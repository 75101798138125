import React, { FC } from 'react';
import { useContent } from 'providers/ContentProvider/ContentProvider';

import { SocialItem } from 'components/Atoms/SocialItem/SocialItem';
import { Wrapper } from './SocialList.style';
import type { SocialIcon } from 'types';
import { containerVariants } from './variants';

export interface SocialListProps {
  withMargin?: boolean;
}

export const SocialList: FC<SocialListProps> = ({ withMargin = false }) => {
  const { socialIcons } = useContent();

  return (
    <Wrapper withMargin={withMargin} variants={containerVariants}>
      {socialIcons.map(({ title, href, icon, id }: SocialIcon) => (
        <SocialItem key={id} title={title} href={href} icon={icon} />
      ))}
    </Wrapper>
  );
};
