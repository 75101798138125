import React, { createContext, FC, useContext } from 'react';

export { HOME_PAGE_FRAGMENT } from 'queries/HOME_PAGE_FRAGMENT';
export { SOCIAL_ICONS_FRAGMENT } from 'queries/SOCIAL_ICONS_FRAGMENT';
export {
  CATEGORIES_GALLERY_FRAGMENT,
  CATEGORIES_MAIN_IMAGE_FRAGMENT,
  CATEGORIES_FRAGMENT,
} from 'queries/CATEGORIES_FRAGMENT';
export { ABOUT_PAGE_FRAGMENT } from 'queries/ABOUT_PAGE_FRAGMENT';
export { CONTACT_PAGE_FRAGMENT } from 'queries/CONTACT_PAGE_FRAGMENT';

import type {
  HomePageData,
  AboutPageData,
  ContactPageData,
  CategoriesPage,
  CategoriesPageData,
  NotFoundPageData,
} from 'types';

type ContextDataType =
  | HomePageData
  | AboutPageData
  | ContactPageData
  | CategoriesPage
  | CategoriesPageData
  | NotFoundPageData
  | undefined;

interface ContentProviderProps {
  readonly data: ContextDataType;
}

const ContentState = createContext<ContextDataType>(undefined);

const ContentProvider: FC<ContentProviderProps> = ({ children, data }) => {
  return <ContentState.Provider value={data}>{children}</ContentState.Provider>;
};

const useContent = () => {
  const data = useContext(ContentState);

  if (data === undefined) {
    throw new Error('useContent must be used within a ContentProvider');
  }

  const {
    socialIcons,
    categories,
    homePageContent,
    aboutPageContent,
    contactPageContent,
    categoriesPageData,
  } = data;

  return {
    socialIcons: socialIcons.nodes,
    categories: categories.nodes,
    homePageContent,
    aboutPageContent,
    contactPageContent,
    categoriesPageData,
  };
};

export { useContent, ContentProvider };
