const transition = { ease: [0.6, 0.01, -0.05, 0.9], duration: 0.7 };

export const itemVariants = {
  hidden: { y: '100%', opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ...transition,
      delay: 0.5,
    },
  },
  exit: { opacity: 0, y: '-100%' },
};
