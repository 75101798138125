import React, { FC, createContext, useState, useContext, useCallback } from 'react';
import { PageProps } from 'gatsby';
// @ts-ignore
import locales from 'config/locales';

import type { Locale } from 'types';

type State = Locale;
type Dispatch = React.Dispatch<React.SetStateAction<Locale>>;

const LocaleContextState = createContext<State>('pl');
const LocaleDispatchContext = createContext<Dispatch>(() => {});

const { siteLanguage: defaultLang } = locales.find(
  ({ default: isDefault }: { default: boolean }) => isDefault,
);

interface LocaleProviderProps extends PageProps {
  locale?: Locale;
}

const LocaleProvider: FC<LocaleProviderProps> = ({ children, locale = 'pl' }) => {
  const [activeLocale, setActiveLocale] = useState(locale);

  return (
    <LocaleContextState.Provider value={activeLocale}>
      <LocaleDispatchContext.Provider value={setActiveLocale}>
        {children}
      </LocaleDispatchContext.Provider>
    </LocaleContextState.Provider>
  );
};

const useLocaleState = () => {
  const activeLocale = useContext(LocaleContextState);
  if (activeLocale === undefined) {
    throw new Error('useLocaleState must be used within a LocaleProvider');
  }

  return { activeLocale };
};

const useLocaleDispatch = () => {
  const { activeLocale } = useLocaleState();

  const setActiveLocale = useContext(LocaleDispatchContext);
  if (setActiveLocale === undefined) {
    throw new Error('useLocaleDispatch must be used within a LocaleProvider');
  }

  const handleUpdateLocale = useCallback((lang: Locale) => setActiveLocale(lang), []);

  const generatePath = (to: string, isLocalizedLink = false) => {
    const isIndex = to === '/';
    const isDefaultLang = defaultLang === activeLocale;
    const path =
      isDefaultLang || isLocalizedLink ? to : `/${activeLocale}${isIndex ? '' : `${to}`}`;
    return path;
  };

  return { handleUpdateLocale, generatePath };
};

export { LocaleProvider, useLocaleState, useLocaleDispatch };
