import React, { FC } from 'react';

import { useNavigationDispatch } from 'providers/NavigationStateProvider/NavigationStateProvider';
import { Text } from 'components/Atoms/Text/Text';
import { useTranslations } from 'hooks/useTranslations';
import { StyledCTA } from './Burger.style';

interface BurgerProps {
  secondary?: boolean;
}

export const Burger: FC<BurgerProps> = ({ secondary = false }) => {
  const { handleToggleMenu } = useNavigationDispatch();
  const { header } = useTranslations();

  return (
    <StyledCTA isButton handleClick={handleToggleMenu}>
      <Text isUpper fontSize="1.4rem" letterSpacing="1px">
        {secondary ? header.open : header.closed}
      </Text>
    </StyledCTA>
  );
};
