import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import type { SocialListProps } from './SocialList';

export const Wrapper = styled(motion.ul)<SocialListProps>`
  display: flex;

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-top: 3rem;

      ${({ theme }) => theme.mq.tablet} {
        margin-top: 5rem;
      }
    `}
`;
