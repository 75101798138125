import React, { FC } from 'react';

import { Rectangle } from 'components/Atoms/Rectangle/Rectangle';
import { Wrapper } from './IntroEntrance.style';
import { rectangleVariants } from './variants';

interface IntroEntranceProps {}

export const IntroEntrance: FC<IntroEntranceProps> = ({ children }) => (
  <>
    <Wrapper>
      <Rectangle variants={rectangleVariants} custom="top" />
      <Rectangle variants={rectangleVariants} custom="bottom" />
    </Wrapper>
    {children}
  </>
);
