import React, { FC, useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { lighten } from 'polished';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import styled from 'styled-components';

import { Navigation } from 'components/Organisms/Navigation/Navigation';
import { Header } from 'components/Organisms/Header/Header';

import type { HomePageData } from 'types';

import { ContentProvider } from 'providers/ContentProvider/ContentProvider';
import { Intro } from 'components/Organisms/Intro/Intro';

type LayoutTypes = HomePageData;

interface LayoutProps extends PageProps {
  data: LayoutTypes;
}

const transition = { ease: [0.6, 0.01, -0.05, 0.9], duration: 0.7 };

const headerVariants = {
  hidden: {
    y: '-100%',
  },

  visible: {
    y: 0,
    transition: {
      ...transition,
    },
  },
  exit: {
    opacity: 0,
    y: '-100%',
  },
};

export const Layout: FC<LayoutProps> = ({ children, path, data }) => {
  const [isVisibility, setIsVisibility] = useState(false);
  const [isIntroVisible, setIsIntroVisible] = useState(true);

  useEffect(() => {
    setIsVisibility(true);
  }, [isVisibility]);

  useEffect(() => {
    setIsIntroVisible(false);
  }, [isIntroVisible]);

  return (
    <>
      <Intro />
      <ContentProvider data={data}>
        <Wrapper isVisibility={isVisibility}>
          <Navigation />
          <Header variants={headerVariants} />

          <AnimateSharedLayout>
            <AnimatePresence exitBeforeEnter>
              <Main key={path} initial="hidden" animate="visible" exit="exit">
                {children}
              </Main>
            </AnimatePresence>
          </AnimateSharedLayout>
        </Wrapper>
      </ContentProvider>
    </>
  );
};

const Wrapper = styled(motion.div)<{ isVisibility: boolean }>`
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  background-image: linear-gradient(
    40deg,
    ${({ theme }) => theme.colors.backgroundDark} 20%,
    ${({ theme }) => lighten(0.1, theme.colors.backgroundDark)} 80%
  );
  visibility: ${({ isVisibility }) => (isVisibility ? 'visible' : 'hidden')};
`;

const Main = styled(motion.main)`
  height: 100%;
  max-height: 100%;
  width: 100%;
`;
