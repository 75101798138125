import React, { FC } from 'react';
import { motion } from 'framer-motion';

import { CTA } from 'components/Atoms/CTA/CTA';
import { useLocaleDispatch } from 'providers/LocaleProvider/LocaleProvider';
import type { Locale } from 'types';
import { Wrapper, StyledText } from './LocaleLink.style';
import { itemVariants } from './variants';

export interface LocaleLinkProps {
  readonly label?: Locale;
  readonly path?: string;
  readonly isActive: boolean;
}

export const LocaleLink: FC<LocaleLinkProps> = ({ label = 'pl', path = '/', isActive }) => {
  const { handleUpdateLocale } = useLocaleDispatch();

  return (
    <Wrapper>
      <CTA to={`/${path}`} handleClick={() => handleUpdateLocale(label)} isLocalizedLink>
        <motion.span variants={itemVariants}>
          <StyledText isActive={isActive}>{label}</StyledText>
        </motion.span>
      </CTA>
    </Wrapper>
  );
};
