import React from 'react';
import { motion } from 'framer-motion';

import { CTA } from 'components/Atoms/CTA/CTA';
import { Wrapper, Icon } from './SocialItem.style';
import { SocialIcon } from 'types';
import { itemVariants } from './variants';

export const SocialItem: React.FC<SocialIcon> = ({ title, href, icon }) => {
  return (
    <Wrapper>
      <CTA isHyperLink href={href} title={title}>
        <motion.div variants={itemVariants}>
          <Icon icon={icon.url} />
        </motion.div>
      </CTA>
    </Wrapper>
  );
};
