import React, { FC, ReactNode } from 'react';
import { Colors, FontWeights } from 'styled-components';

import { Typography } from './Text.style';
import type { TextTag, TextVariant } from 'types';

export interface TextProps {
  readonly children: ReactNode;
  readonly isUpper?: boolean;
  readonly isLower?: boolean;
  readonly noWrap?: boolean;
  readonly variant?: TextVariant | null;
  readonly color?: keyof Colors;
  readonly fontWeight?: keyof FontWeights;
  readonly fontSize?: string | null;
  readonly letterSpacing?: string;
  readonly tag?: TextTag;
}

export const Text: FC<TextProps> = ({
  children,
  isUpper = false,
  isLower = false,
  noWrap = false,
  color = 'textLight',
  fontWeight = 'regular',
  fontSize = null,
  letterSpacing = '0',
  variant = null,
  tag = 'p',
  ...rest
}) => {
  return (
    <Typography
      variant={variant}
      color={color}
      noWrap={noWrap}
      isUpper={isUpper}
      isLower={isLower}
      fontWeight={fontWeight}
      fontSize={fontSize}
      letterSpacing={letterSpacing}
      as={tag}
      {...rest}
    >
      {children}
    </Typography>
  );
};
