import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { calcHeight } from './mixins';

export const GlobalStyled = createGlobalStyle`
  ${normalize}
  
 *, *::after, *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  line-height: 1.5;
  height: -webkit-fill-available;
}

body {
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.font.family.primary};
  text-rendering: optimizeLegibility;
  height: 100vh;
  ${calcHeight(100)};
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  overflow: hidden;
  

  #___gatsby{
    height: 100%;
    width: 100%;
  }

  #gatsby-focus-wrapper{
    height: 100%;
    width: 100%;
  }
}

.visually-hidden {
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  padding: 0;
  width: 1px;
}

ul {
    list-style: none;
}  

img {
  display: block;
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  background: none;
}


::-webkit-scrollbar {
     width: 0;
 }
 
 
`;
