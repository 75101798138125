import React from 'react';
import { motion } from 'framer-motion';

import { CTA } from 'components/Atoms/CTA/CTA';
import { Text } from 'components/Atoms/Text/Text';
import {
  Wrapper,
  ImageWrapper,
  Image,
  LineWrapper,
  InnerWrapper,
  TextWrapper,
} from './CategoryItem.style';
import { getNumberOfItem } from 'helpers';
import type { CategoryElement } from 'types';
import { textVariants, numberVariants, imageVariants, itemVariants } from './variants';

export interface CategoryItemProps extends CategoryElement {
  readonly isEven: boolean;
  readonly numberOfItem: number;
  isDragging: boolean;
}

export const CategoryItem: React.FC<CategoryItemProps> = ({
  title,
  mainImage: { alt, gatsbyImageData },
  slug,
  isEven,
  numberOfItem,
  isDragging = false,
}) => {
  return (
    <motion.div variants={itemVariants}>
      <Wrapper initial="hidden" whileHover="hover" isEven={isEven} isDragging={isDragging}>
        <CTA to={`/${slug}`} draggable={false}>
          <ImageWrapper>
            <motion.div variants={imageVariants}>
              <Image image={gatsbyImageData} alt={alt} />
            </motion.div>
          </ImageWrapper>
          <LineWrapper isEven={isEven}>
            <InnerWrapper>
              <TextWrapper variants={numberVariants}>
                <Text fontSize="1.2rem">{getNumberOfItem(numberOfItem)}</Text>
              </TextWrapper>
              <TextWrapper variants={textVariants}>
                <Text fontSize="1.2rem">{title}</Text>
              </TextWrapper>
            </InnerWrapper>
          </LineWrapper>
        </CTA>
      </Wrapper>
    </motion.div>
  );
};
