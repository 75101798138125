import React, { FC, useState } from 'react';

import { Wrapper, Word } from './Words.style';

interface WordsProps {
  separator?: string;
  duration?: number;
  delay?: number;
  exit: object;
}

export const Words: FC<WordsProps> = ({
  children,
  separator = '',
  duration = 0.15,
  delay = 0,
  ...props
}) => {
  const [isOverflow, setIsOverflow] = useState(true);

  if (!children) {
    return null;
  }

  //@ts-ignore
  const words = children.split(separator);

  const container = {
    visible: (i = 1) => ({
      transition: { staggerChildren: duration, delayChildren: i * delay },
    }),
  };

  const child = {
    hidden: {
      y: '40%',
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        damping: 20,
      },
    },
  };

  return (
    <Wrapper
      isOverflow={isOverflow}
      initial="hidden"
      animate="visible"
      variants={container}
      onAnimationComplete={() => setIsOverflow(false)}
      {...props}
    >
      {words.map((word: string, index: number) => (
        <Word key={index} aria-hidden variants={child}>
          {word === ' ' ? <span>&nbsp;</span> : word}
        </Word>
      ))}
    </Wrapper>
  );
};
