import { useEffect, useState } from 'react';

import { isBrowser } from 'helpers';

interface WindowSize {
  width: number;
  height: number;
}

const DEBOUNCE_TIMEOUT = 150;

const getSize = (): WindowSize => ({
  width: isBrowser() ? window.innerWidth : 0,
  height: isBrowser() ? window.innerHeight : 0,
});

/**
 * Hook: useWindowSize
 */
export const useWindowSize = (): WindowSize => {
  // Initialize state with null width/height so server and client renders match
  const [windowSize, setWindowSize] = useState<WindowSize>(getSize());

  useEffect(() => {
    // timeout for debounce mechanism
    let timeoutId: NodeJS.Timeout;

    // Handler to call on window resize
    const handleResize = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);

      // change width/height from the state object after 150ms
      timeoutId = setTimeout(() => setWindowSize(getSize()), DEBOUNCE_TIMEOUT);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);
    // window.addEventListener('onLoad', loadResize);

    // Remove event listener on cleanup
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};
