import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 5%;
  margin: 1rem 0 0;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.level1};

  ${({ theme }) => theme.mq.tablet} {
    margin: 10rem 0 0;
  }
`;
