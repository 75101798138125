import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  margin-top: 4rem;
  justify-content: flex-start;
  align-items: center;
`;

export const Overflow = styled.div<{ isDragging: boolean }>`
  max-height: 40rem;
  overflow: hidden;
  cursor: grab;

  ${({ isDragging }) =>
    isDragging &&
    css`
      cursor: grabbing;
    `}
`;
