import { motion } from 'framer-motion';
import React, { FC } from 'react';

import { Wrapper, StyledText, CTALink } from './NavItem.style';
import { itemVariants } from './variants';

interface NavItemProps {
  readonly path?: string;
  readonly label: string;
}

export const NavItem: FC<NavItemProps> = ({ path, label }) => {
  return (
    <Wrapper>
      <CTALink to={path} activeClassName="active">
        <motion.span variants={itemVariants}>
          <StyledText>{label}</StyledText>
        </motion.span>
      </CTALink>
    </Wrapper>
  );
};
