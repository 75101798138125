import React, { FC, useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import { GlobalStyled } from 'assets/styles/GlobalStyles';
import { theme } from 'assets/styles/theme';
import { useWindowSize } from 'hooks/useWindowResize';

const INITIAL_CONTAINING_BLOCK = 0.01;

export const SCThemeProvider: FC<PageProps> = ({ children }) => {
  const { height } = useWindowSize();
  const [vh, setVh] = useState(0);

  useEffect(() => {
    if (height) {
      setVh(height * INITIAL_CONTAINING_BLOCK);
    }
  }, [height]);

  return (
    <ThemeProvider theme={{ ...theme, vh }}>
      <GlobalStyled />
      {children}
    </ThemeProvider>
  );
};
