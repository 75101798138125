import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import type { HeaderProps } from './Header';

export const Wrapper = styled(motion.header)<HeaderProps>`
  display: flex;
  justify-content: flex-end;
  padding: 3rem 3% 0;
  max-width: ${({ theme }) => theme.layout.maxContainerWidth};
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.level2};

  ${({ secondary }) =>
    secondary &&
    css`
      justify-content: space-between;
    `};
`;

export const InnerWrapper = styled.div<HeaderProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.level2};

  ${({ secondary }) =>
    secondary &&
    css`
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    `}
`;
