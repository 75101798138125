import React from 'react';
import { AnimatePresence, MotionConfig } from 'framer-motion';

import { SCThemeProvider } from 'providers/ThemeProvider/ThemeProvider';
import { NavigationStateProvider } from 'providers/NavigationStateProvider/NavigationStateProvider';
import { LocaleProvider } from 'providers/LocaleProvider/LocaleProvider';
import { ContentProvider } from 'providers/ContentProvider/ContentProvider';

import { Layout } from 'templates/Layout/Layout';

const transition = { ease: [0.6, 0.01, -0.05, 0.9], duration: 0.55 };

export const wrapPageElement = ({ element, props }) => {
  return (
    <MotionConfig transition={{ ...transition }}>
      <LocaleProvider locale={props.pageContext.locale}>
        <ContentProvider data={props.data}>
          <NavigationStateProvider {...props}>
            <Layout {...props}>{element}</Layout>
          </NavigationStateProvider>
        </ContentProvider>
      </LocaleProvider>
    </MotionConfig>
  );
};

export const wrapRootElement = ({ element }) => <SCThemeProvider>{element}</SCThemeProvider>;
