import styled from 'styled-components';

export const Wrapper = styled.li`
  margin-right: 2rem;
  overflow: hidden;

  :last-child {
    margin-right: 0;
  }
`;

export const Icon = styled.div<{ icon: string }>`
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
    transform: scale(0);
    transition: transform ${({ theme }) => theme.transition.primary};
  }

  :hover::after {
    transform: scale(1);
  }
`;
