import styled, { css } from 'styled-components';

import { Text } from 'components/Atoms/Text/Text';
import type { LocaleLinkProps } from './LocaleLink';

export const Wrapper = styled.li`
  margin-right: 3rem;
  overflow: hidden;
`;

export const StyledText = styled(Text)<LocaleLinkProps>`
  color: ${({ theme }) => theme.colors.grey};
  padding: 0.5rem 0;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
    transform: scale(0);
    transition: transform ${({ theme }) => theme.transition.primary};
  }

  :hover::after {
    transform: scale(1);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.textLight};
    `}
`;
