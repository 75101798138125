export const i18n = {
  en: {
    menu: [
      { label: 'Home', path: '/', id: 0 },
      { label: 'About', path: '/about', id: 1 },
      { label: 'Category', path: '/categories', id: 2 },
      { label: 'Contact', path: '/contact', id: 3 },
    ],
    header: {
      closed: 'menu',
      open: 'close',
    },
    navFooter: {
      author: 'Design and implementation - Mateusz Hadryś',
      owner: `© ${new Date().getFullYear()} Sebastian Samulski`,
    },
    contactForm: {
      email: {
        label: 'email',
      },
      title: {
        label: 'title',
      },
      message: {
        label: 'message',
      },
      send: {
        label: 'send',
      },
      buttonStatus: {
        success: 'success',
        error: 'Something wrong...',
      },
      requiredMessage: 'Required field',
      emailMessage: 'Invalid email',
    },
    back: 'Go back',
  },

  pl: {
    menu: [
      { label: 'Strona główna', path: '/', id: 0 },
      { label: 'O mnie', path: '/o-mnie', id: 1 },
      { label: 'Kategorie', path: '/kategorie', id: 2 },
      { label: 'Kontakt', path: '/kontakt', id: 3 },
    ],
    header: {
      closed: 'menu',
      open: 'zamknij',
    },
    navFooter: {
      author: 'Projekt i realizacja - Mateusz Hadryś',
      owner: `© ${new Date().getFullYear()} Sebastian Samulski`,
    },
    contactForm: {
      email: {
        label: 'email',
      },
      title: {
        label: 'tytuł',
      },
      message: {
        label: 'wiadomość',
      },
      send: {
        label: 'wyślij',
      },
      buttonStatus: {
        success: 'Wiadomość wysłana',
        error: 'Coś poszło nie tak...',
      },
      requiredMessage: 'Wymagane pole',
      emailMessage: 'Niepoprawy adres email',
    },
    back: 'Powrót',
  },
};
