import { useLocaleDispatch } from 'providers/LocaleProvider/LocaleProvider';
import React, { FC } from 'react';

import { StyledLink as Link, Button, Anchor } from './CTA.styles';

type ButtonType = 'button' | 'submit' | 'reset';

interface CTAProps {
  readonly to?: string;
  readonly title?: string;
  readonly isButton?: boolean;
  readonly isLocalizedLink?: boolean;
  readonly isHyperLink?: boolean;
  readonly href?: string | undefined;
  readonly handleClick?: () => void;
  readonly type?: ButtonType;
  readonly draggable?: boolean;
  readonly activeClassName?: string;
}

export const CTA: FC<CTAProps> = ({
  children,
  to = '/',
  isButton = false,
  isHyperLink = false,
  isLocalizedLink = false,
  draggable = true,
  type = 'button',
  title = 'title',
  href = '',
  handleClick,
  activeClassName = '',
  ...rest
}) => {
  const { generatePath } = useLocaleDispatch();
  const path = generatePath(to, isLocalizedLink);

  if (isButton) {
    return (
      <Button
        type={type}
        whileHover={{ scale: 1.05 }}
        role="button"
        onClick={handleClick}
        {...rest}
      >
        {children}
      </Button>
    );
  }

  if (isHyperLink) {
    return (
      <Anchor
        href={href}
        target="_blank"
        title={title}
        rel="noopener noreferrer"
        draggable={draggable}
        {...rest}
      >
        {children}
      </Anchor>
    );
  }

  return (
    <Link
      to={path}
      activeClassName={activeClassName}
      draggable={draggable}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </Link>
  );
};
