import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.li)<{ isEven: boolean; isDragging: boolean }>`
  position: relative;
  width: 100%;
  max-width: 40rem;
  width: 30vw;
  cursor: pointer;

  ${({ isEven }) =>
    !isEven &&
    css`
      transform: translateX(50%);
    `}

  ${({ isDragging }) =>
    isDragging &&
    css`
      pointer-events: none;
    `}
`;

export const ImageWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  border-radius: 0.2rem;
  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.backgroundDark};
    opacity: 0.4;

    transition: opacity ${({ theme }) => theme.transition.primary};
  }

  :hover ::after {
    opacity: 0;
  }
`;

export const Image = styled(GatsbyImage)`
  display: block;
  pointer-events: none;
`;

export const LineWrapper = styled.div<{ isEven: boolean }>`
  position: absolute;
  top: 0;
  left: 20%;
  width: 100%;
  height: 100%;
  pointer-events: none;

  ${({ isEven }) =>
    !isEven &&
    css`
      left: 80%;
    `}

  ::after {
    content: '';
    position: absolute;
    top: -1rem;
    left: 0;
    height: 3rem;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.textLight};
  }
`;

export const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: -50%;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  transform: translateY(-3.5rem);
  overflow: hidden;
`;

export const TextWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
`;
