const transition = { ease: [0.6, 0.01, -0.05, 0.9], duration: 0.7 };

export const containerVariants = {
  visible: {
    transition: {
      ...transition,
      delayChildren: 0.5,
      staggerChildren: 0.2,
    },
  },
};
