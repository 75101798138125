import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  ${center};
  color: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const Button = styled(motion.button)`
  ${center};
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Anchor = styled.a`
  text-decoration: none;
  color: inherit;
`;
