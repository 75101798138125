import React, { useRef, useState } from 'react';

import { CategoryItem } from 'components/Atoms/CategoryItem/CategoryItem';
import { useContent } from 'providers/ContentProvider/ContentProvider';

import { Wrapper, Overflow } from './CategoriesList.style';
import type { CategoryElement } from 'types';
import { containerVariants } from './varisnts';

export const CategoriesList: React.FC = () => {
  const [isDragging, setIsDragging] = useState(false);
  const { categories } = useContent();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Overflow isDragging={isDragging} ref={ref}>
      <Wrapper
        variants={containerVariants}
        drag="y"
        dragConstraints={ref}
        dragElastic={0.7}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={() => setIsDragging(false)}
      >
        {categories.map((item: CategoryElement, index: number) => (
          <CategoryItem
            key={item.id}
            numberOfItem={index}
            isEven={index % 2 === 0}
            {...item}
            isDragging={isDragging}
          />
        ))}
      </Wrapper>
    </Overflow>
  );
};
