export const textVariants = {
  hidden: {
    y: '-100%',
    oapcity: 0,
  },
  hover: {
    y: 0,
    opacity: 1,
  },
};

export const numberVariants = {
  hidden: {
    y: 0,
    opacity: 1,
  },
  hover: {
    y: '-100%',
    oapcity: 0,
  },
};

export const imageVariants = {
  hidden: {
    scale: 1,
    x: 0,
  },
  hover: {
    scale: 1.05,
    x: -20,
  },
};

export const itemVariants = {
  hidden: { y: '100%', opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
  exit: { opacity: 0, y: '-100%' },
};
