import styled, { css } from 'styled-components';

import { Text } from 'components/Atoms/Text/Text';
import type { LogoProps } from './Logo';

export const Wrapper = styled.div<LogoProps>`
  color: ${({ theme }) => theme.colors.textLight};

  ${({ secondary }) =>
    secondary &&
    css`
      display: flex;
    `}
`;

export const InnerWrapper = styled.div`
  margin-left: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  word-break: break-all;
`;

export const Line = styled.span`
  position: absolute;
  width: 1.5px;
  height: 90%;
  top: 5%;
  bottom: 5%;
  left: -0.5rem;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
`;

export const StyledText = styled(Text)`
  display: flex;
  flex-direction: column;
`;

export const Svg = styled.svg`
  margin: auto 0;
`;
