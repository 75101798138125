import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4rem;

  ${({ theme }) => theme.mq.tablet} {
    margin-top: 6.5rem;
  }
`;
