import type { Origin } from 'types';

const transition = { ease: [0.6, 0.01, -0.05, 0.9], duration: 0.7 };

export const rectangleVariants = {
  hidden: (custom: Origin) => ({
    scaleY: 0,
    originY: custom,
  }),

  visible: {
    scaleY: 1,
  },

  exit: {
    scaleY: 0,
    transition: { ...transition, delay: 0.7 },
  },
};
