const transition = { ease: [0.6, 0.01, -0.05, 0.9], duration: 0.7 };

export const headerVariants = {
  hidden: {
    opacity: 0,
    y: '-100%',
  },

  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ...transition,
      delay: 0.5,
    },
  },

  exit: {
    opacity: 0,
    y: '-100%',
  },
};
