import React from 'react';
import { Variants } from 'framer-motion';

import { Burger } from 'components/Atoms/Burger/Burger';
import { Logo } from 'components/Atoms/Logo/Logo';
import { Wrapper, InnerWrapper } from './Header.style';

export interface HeaderProps {
  secondary?: boolean;
  variants?: Variants | undefined;
}

export const Header: React.FC<HeaderProps> = ({ secondary = false, variants }) => {
  return (
    <Wrapper
      secondary={secondary}
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={variants}
    >
      <InnerWrapper secondary={secondary}>
        <Logo width="3.5rem" secondary={secondary} />
        <Burger secondary={secondary} />
      </InnerWrapper>
    </Wrapper>
  );
};
