import React, { FC, createContext, useState, useContext, useEffect, useCallback } from 'react';
import { PageProps } from 'gatsby';

type State = boolean;
type Dispatch = React.Dispatch<React.SetStateAction<boolean>> | undefined;

const NavigationStateContext = createContext<State>(false);
const NavigationDispatchContext = createContext<Dispatch>(() => {});

const NavigationStateProvider: FC<PageProps> = ({ children, location }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <NavigationStateContext.Provider value={isOpen}>
      <NavigationDispatchContext.Provider value={setIsOpen}>
        {children}
      </NavigationDispatchContext.Provider>
    </NavigationStateContext.Provider>
  );
};
interface NavigationState {
  isMenuOpen: State;
}

const useNavigationState = (): NavigationState => {
  const isMenuOpen = useContext(NavigationStateContext);
  if (isMenuOpen === undefined) {
    throw new Error('useNavigationState must be used within a NavigationStateProvider');
  }
  return { isMenuOpen };
};

interface NavigationDispatch {
  handleToggleMenu: () => void;
  handleCloseMenu: () => void;
  handleOpenMenu: () => void;
}

const useNavigationDispatch = (): NavigationDispatch => {
  const setMenuOpen = useContext(NavigationDispatchContext);
  if (setMenuOpen === undefined) {
    throw new Error('useNavigationDispatch must be used within a NavigationStateProvider');
  }

  const handleToggleMenu = () => setMenuOpen((prevState) => !prevState);

  const handleCloseMenu = () => setMenuOpen(false);

  const handleOpenMenu = () => setMenuOpen(true);

  return {
    handleToggleMenu,
    handleCloseMenu,
    handleOpenMenu,
  };
};

const useNavigation = (): [NavigationState, NavigationDispatch] => [
  useNavigationState(),
  useNavigationDispatch(),
];

export { NavigationStateProvider, useNavigationState, useNavigationDispatch, useNavigation };
