import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.level5};
`;

export const InnerWrapper = styled.nav`
  padding: 0 5%;
  display: grid;
  grid-template-columns: 1fr;
  max-width: ${({ theme }) => theme.layout.maxContainerWidth};
  margin: 0 auto;
  height: auto;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.level1};

  ${({ theme }) => theme.mq.phone} {
    grid-template-columns: 0.7fr 1.3fr;
  }

  ${({ theme }) => theme.mq.tablet} {
  }
`;

export const Box = styled.div<{ secondary?: boolean }>`
  margin-top: 4rem;

  ${({ theme }) => theme.mq.phone} {
    margin-top: 8rem;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      align-self: center;
      margin: 0;

      ${({ theme }) => theme.mq.phone} {
        margin: 0;
      }
    `}
`;
