import { DefaultTheme, Breakpoints, Colors } from 'styled-components';

export const breakpoints: Breakpoints = {
  phone: 374,
  tablet: 767,
  bigTablet: 1020,
  desktop: 1150,
  bigDesktop: 1440,
  huge: 1700,
};

const colors: Colors = {
  background: '#F9F9F9',
  white: '#FFFFFF',
  black: '#000000',
  backgroundDark: '#1c1b20',
  backgroundLight: '#fdfdfd',
  textDark: '#101012',
  textLight: '#f8f8f8',
  grey: '#737373',
};

export const theme: DefaultTheme = {
  colors,
  font: {
    family: {
      primary: `'Poppins', sans-serif`,
      secondary: `'Raleway', sans-serif`,
    },
    weight: {
      light: 300,
      regular: 400,
      semiBold: 600,
      bold: 700,
    },
    size: {
      headers: {
        s: '2.4rem',
        m: '3.6rem',
        l: '4.8rem',
        xl: '7.2rem',
        xxl: '9.6rem',
      },
      body: {
        s: '1rem',
        m: '1.2rem',
        l: '1.4rem',
        xl: '1.6rem',
        xxl: '1.8rem',
      },
    },
  },

  transition: {
    primary: '400ms cubic-bezier(0.5, 0, 0, 0.5) 0ms',
  },

  layout: {
    maxContainerWidth: '160.4rem',
  },

  mq: Object.keys(breakpoints).reduce<Record<string, string>>((acc, breakpoint) => {
    acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;

    return acc;
  }, {}),

  zIndex: {
    level1: '1000',
    level2: '2000',
    level3: '3000',
    level4: '4000',
    level5: '5000',
    level6: '6000',
    level7: '7000',
    level8: '8000',
    level9: '9000',
    level10: '10000',
    level11: '11000',
  },
  vh: 1,
};
