import React from 'react';

import { Text } from 'components/Atoms/Text/Text';
import { useTranslations } from 'hooks/useTranslations';
import { Wrapper } from './FooterNav.style';
import { itemVariants } from './variants';

export const FooterNav: React.FC = () => {
  const { navFooter } = useTranslations();

  return (
    <Wrapper variants={itemVariants}>
      <Text color="grey" fontSize="1.4rem">
        {navFooter.owner}
      </Text>

      <Text color="grey" fontSize="1rem">
        {navFooter.author}
      </Text>
    </Wrapper>
  );
};
