module.exports = [
  {
    default: true,
    path: '',
    label: 'pl',
    locale: 'pl',
    siteLanguage: 'pl',
  },
  {
    path: 'en',
    label: 'en',
    locale: 'en-GB',
    siteLanguage: 'en',
  },
];
