import type { Locale } from 'types';

/*
 * Check if global object window if defined
 */
export const isBrowser = () => typeof window !== 'undefined';

/**
 * Get chunk from Array
 * @param  {any[]=[]} array
 * @param  {number=1} chunk
 */
export const chunkArray = (array: any[] = [], chunk: number = 1) => {
  const newArr = [...array];
  return [...Array(Math.ceil(newArr.length / chunk))].map((_) => newArr.splice(0, chunk));
};

/**
 * Start as initial value, end as last value, step as array step
 * @param  {number} start
 * @param  {number} end
 * @param  {number} step
 * @param  {number} offset
 */
export const getRange = (start: number, end: number, step: number, offset: number) => {
  const len = (Math.abs(end - start) + (offset || 0) * 2) / (step || 1) + 1;
  const direction = start < end ? 1 : -1;
  const startingPoint = start - direction * (offset || 0);
  const stepSize = direction * (step || 1);

  return Array(len)
    .fill(0)
    .map((_, index) => {
      return startingPoint + stepSize * index;
    });
};

interface CurrentNavItemFunc {
  menu: any[];
  elements: number[];
}

/**
 * Get current nav element
 * @param  {i18} {menu
 * @param  {CurrentNavItemFunc} elements}
 * @returns any
 */
export const getCurrentNavItem = ({ menu, elements }: CurrentNavItemFunc): any[] => {
  const result = menu.filter(({ id }: { id: number }) => {
    return elements.indexOf(id) !== -1 || null;
  });

  return result;
};

/**
 * Get number of items (UI)
 * @param  {number} index
 * @returns {number | string}
 */
export const getNumberOfItem = (index: number): number | string => {
  if (index < 10) {
    return `0${index + 1}`;
  }

  return index + 1;
};

const defaultMainLabel = {
  pl: 'główna',
  en: 'home',
};

/**
 * @param  {any} item
 * @param  {Locale} activeLocale
 */
export const getItemData = (item: any, activeLocale: Locale) => {
  const { label = defaultMainLabel[activeLocale], path = '/' } = item || {};

  return {
    label,
    path,
  };
};
