import styled from 'styled-components';

import { Text } from 'components/Atoms/Text/Text';
import { CTA } from 'components/Atoms/CTA/CTA';

const ACTIVE_CLASS_NAME = '.active';

export const CTALink = styled(CTA)``;

export const Wrapper = styled.li`
  margin-bottom: 1.4rem;
  overflow: hidden;

  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledText = styled(Text)`
  padding: 0.5rem 1rem 0.5rem 0;
  position: relative;
  font-size: 2rem;
  white-space: nowrap;

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.headers.m};
  }

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -0.5rem;
    width: 100%;
    height: 0.3rem;
    background-color: ${({ theme }) => theme.colors.white};
    transform-origin: left;
    will-change: transform;
    transform: scaleX(0);
    transition: transform ${({ theme }) => theme.transition.primary};
  }

  ${CTALink}${ACTIVE_CLASS_NAME} &::before {
    transform: scale(1);
  }

  :hover ::before {
    transform: scale(1);
  }
`;
