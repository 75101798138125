import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.level9};
  display: flex;
`;

export const TextWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Svg = styled(motion.svg)`
  margin: auto 0;
  color: transparent;
`;

export const Figure = styled(motion.figure)`
  position: fixed;
  top: calc(50% - 8rem);
  left: calc(50% - 8rem);
  transform-origin: center;
  width: 16rem;
  height: 16rem;
  padding: 0;
  margin: 0;
  pointer-events: none;
  opacity: 0;
`;

export const Path = styled(motion.path)`
  stroke: ${({ theme }) => theme.colors.textLight};
  stroke-width: 5;
  stroke-linejoin: round;
  stroke-linecap: round;
`;
