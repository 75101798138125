import React, { FC } from 'react';

import { NavItem } from 'components/Atoms/NavItem/NavItem';
import { useTranslations } from 'hooks/useTranslations';
import { Wrapper } from './NavList.style';
import { containerVariants } from './variants';

export const NavList: FC = () => {
  const { menu } = useTranslations();

  return (
    <Wrapper variants={containerVariants}>
      {menu.map(({ label, path }) => (
        <NavItem key={label} label={label} path={path} />
      ))}
    </Wrapper>
  );
};
