import React, { FC } from 'react';
import { Variants } from 'framer-motion';

import { StyledRectangle } from './Rectangle.style';
import type { Origin } from 'types';

interface RectangleProps {
  variants?: Variants;
  custom?: Origin;
  exit?: string;
}

export const Rectangle: FC<RectangleProps> = ({ variants, custom = 'bottom', exit }) => {
  return <StyledRectangle variants={variants} custom={custom} exit={exit} />;
};
