import type { Origin } from 'types';

const transition = { ease: [0.6, 0.01, -0.05, 0.9], duration: 0.7 };

export const rectangleVariants = {
  hidden: (custom: Origin) => ({
    scaleY: 0,
    originY: custom,
    transition: {
      ...transition,
      delay: 0.3,
    },
  }),

  visible: {
    scaleY: 1,
  },
};

export const childVariantHead = {
  visible: {
    scale: 1,
    rotate: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.5,
      delay: 1.2,
    },
  },
  hidden: {
    scale: 0,
    rotate: 15,
    transition: {
      ease: 'easeInOut',
      duration: 0.5,
    },
  },
};
