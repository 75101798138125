import styled from 'styled-components';
import { motion } from 'framer-motion';

import { calcHeight } from 'assets/styles/mixins';

export const StyledRectangle = styled(motion.div)`
  width: 100%;
  height: 100vh;
  ${calcHeight(100)};
  background-color: ${({ theme }) => theme.colors.backgroundDark};
`;
