import React, { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { IntroEntrance } from 'components/Molecules/IntroEntrance/IntroEntrance';
import { LangNav } from 'components/Molecules/LangNav/LangNav';
import { NavList } from 'components/Molecules/NavList/NavList';
import { SocialList } from 'components/Molecules/SocialList/SocialList';
import { CategoriesList } from 'components/Molecules/CategoriesList/CategoriesList';
import { Header } from 'components/Organisms/Header/Header';
import { FooterNav } from 'components/Molecules/FooterNav/FooterNav';
import { useNavigationState } from 'providers/NavigationStateProvider/NavigationStateProvider';
import { Wrapper, InnerWrapper, Box } from './Navigation.style';
import { useWindowSize } from 'hooks/useWindowResize';
import { breakpoints } from 'assets/styles/theme';
import { headerVariants } from './variants';

export const Navigation: FC = () => {
  const { isMenuOpen } = useNavigationState();
  const { width } = useWindowSize();

  return (
    <AnimatePresence>
      {isMenuOpen && (
        <Wrapper initial="hidden" animate="visible" exit="exit">
          <IntroEntrance>
            <motion.div variants={headerVariants}>
              <Header secondary />
            </motion.div>
            <InnerWrapper>
              <Box>
                <LangNav />
                <NavList />
                <SocialList withMargin />
              </Box>
              {width >= breakpoints.tablet && (
                <Box secondary>
                  <CategoriesList />
                </Box>
              )}
            </InnerWrapper>
            <FooterNav />
          </IntroEntrance>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};
