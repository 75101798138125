import styled from 'styled-components';

import { CTA } from 'components/Atoms/CTA/CTA';

export const StyledCTA = styled(CTA)`
  margin-top: 1rem;
  padding: 0.5rem;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
    transform: scale(0);
    transition: transform ${({ theme }) => theme.transition.primary};
  }

  :hover::after {
    transform: scale(1);
  }
`;
