import React, { FC } from 'react';
const locales = require('config/locales');

import { LocaleLink } from 'components/Atoms/LocaleLink/LocaleLink';
import { useLocaleState } from 'providers/LocaleProvider/LocaleProvider';
import { List } from './LangNav.style';
import type { LangConfig } from 'types';
import { containerVariants } from './variants';

export const LangNav: FC = () => {
  const { activeLocale } = useLocaleState();

  return (
    <List variants={containerVariants}>
      {locales.map(({ label, path }: LangConfig) => (
        <LocaleLink key={label} label={label} path={path} isActive={activeLocale === label} />
      ))}
    </List>
  );
};
