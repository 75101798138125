import styled, { css } from 'styled-components';

import type { TextProps } from './Text';

export const Typography = styled.p<TextProps>`
  color: ${({ theme, color }) => theme.colors[color!]};
  font-weight: ${({ theme, fontWeight }) => theme.font.weight[fontWeight!]};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  font-size: ${({ fontSize }) => fontSize};

  ${({ isUpper }) =>
    isUpper &&
    css`
      text-transform: uppercase;
    `}

  ${({ isLower }) =>
    isLower &&
    css`
      text-transform: lowercase;
    `}

    ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}

  ${({ variant }) =>
    variant === 'heading' &&
    css`
      font-size: ${({ theme }) => theme.font.size.headers.m};

      ${({ theme }) => theme.mq.tablet} {
        font-size: ${({ theme }) => theme.font.size.headers.m};
      }

      ${({ theme }) => theme.mq.bigTablet} {
        font-size: ${({ theme }) => theme.font.size.headers.l};
      }
    `}

  ${({ variant }) =>
    variant === 'normal' &&
    css`
      font-size: ${({ theme }) => theme.font.size.body.l};

      ${({ theme }) => theme.mq.tablet} {
        font-size: ${({ theme }) => theme.font.size.body.xl};
      }

      ${({ theme }) => theme.mq.bigTablet} {
        font-size: ${({ theme }) => theme.font.size.body.xxl};
      }
    `}
`;
